import { PageLayout } from "~/lib/ui/page-layout";
import { Button, Dialog, getIcon, Label } from "~/lib/ui";
import { DateRangePopover } from "~/lib/ui/calendar/date-range-popover";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import CreatePriceAnalysisDialog from "~/pages/procurement/_cmp/dialog/create-price-analysis-dialog";
import { DateRange } from "~/lib/utils/date/date-utils.types";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import { getOffsetDate } from "~/lib/utils/date/date-utils";
import CompaniesVendorSelection from "~/lib/ui/vendor/companies-vendor-selection";
import PageTabs from "~/lib/ui/tabs/page-tabs";
import { Outlet } from "react-router";
import { useFeatureFlags } from "~/lib/feature-flags";
import { useDateRangeParams } from "~/lib/utils/use-date-range-params";

export type ProcurementPageSearchParams = {
  companiesVendorIds?: Array<string>;
};

export const CACHE_PROCUREMENT = "procurement";

export default function ProcurementPage() {
  const [dateParams, setDateParams] = useDateRangeParams({
    from: new Date(getOffsetDate(new Date(), -365)),
    to: new Date(),
  });
  const [searchParams, setSearchParams] = useTypedSearchParams<ProcurementPageSearchParams>();
  const { t } = useTranslation();
  const flags = useFeatureFlags();

  const handleDateRangeChange = ({ from, to }: DateRange) => {
    setDateParams({ from, to });
  };

  const handleVendorChange = (companiesVendorIds: Array<string>) => {
    setSearchParams(
      "companiesVendorIds",
      companiesVendorIds.length > 0 ? companiesVendorIds : undefined
    );
  };

  const companiesVendorIds = searchParams.companiesVendorIds ?? [];

  const tabs = [
    {
      id: "price_analysis",
      label: t("procurement:tabs.price_analysis"),
      Icon: getIcon("chart"),
      to: "",
    },
    {
      id: "price_file_analysis",
      label: t("procurement:tabs.price_file_analysis"),
      Icon: getIcon("file"),
      to: "price-files",
    },
    {
      id: "order_complaints",
      label: t("procurement:order_complaints.title"),
      Icon: getIcon("warningTriangle"),
      to: "order-complaints",
    },
  ];

  const featureTabs = tabs.filter((tab) => {
    if (tab.id === "price_file_analysis") {
      return flags.has("cp3.procurement.price_file_analysis");
    }
    if (tab.id === "order_complaints") {
      return flags.has("cp3.procurement.order_complaints");
    }

    return true;
  });

  return (
    <PageLayout
      title={t("procurement:title")}
      renderDescription={() => (
        <div className="flex w-full gap-4">
          <div className="shrink-0">
            <Label>{t("common:date_range")}</Label>
            <DateRangePopover
              compact={false}
              dateFrom={dateParams.from}
              dateTo={dateParams.to}
              onChange={handleDateRangeChange}
              allowToDateInFuture={true}
            />
          </div>
          <div className="max-w-xl">
            <CompaniesVendorSelection
              defaultSelected={companiesVendorIds}
              onSelect={handleVendorChange}
              dateRange={{
                from: dateParams.from,
                to: dateParams.to,
              }}
            />
          </div>
        </div>
      )}
      renderActions={() => (
        <Dialog
          triggerAsChild
          trigger={
            <Button Icon={getIcon("add")} variant="tertiary">
              {t("common:create", {
                entity: t("common:price_analysis", { count: 1 }).toLowerCase(),
              })}
            </Button>
          }
          render={({ onClose }) => (
            <CreatePriceAnalysisDialog
              onClose={onClose}
              dateFrom={dateParams.from}
              dateTo={dateParams.to}
            />
          )}
        />
      )}
    >
      <PageTabs
        preserveSearchParams
        searchParamsToPreserve={["dateFrom", "dateTo", "vendorIds"]}
        tabs={featureTabs}
      />
      <Suspense>
        <Outlet context={{ dateRange: dateParams }} />
      </Suspense>
    </PageLayout>
  );
}
