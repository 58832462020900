import { ResponseError } from "@apacta/sdk";
import {
  ApactaBackendCause,
  ApactaErrorResponse,
  BackendValidationError,
  FieldErrors,
} from "./types";
import { ClientError } from "graphql-request";

/** Parsing of API errors
 *
 * Supports both REST and GraphQL errors.
 * It's async due to JSON being a promise on the response for REST errors
 *
 * @returns {ErrorResponse} A structured error response or null if the error is not supported
 *
 */
export async function parseError(error: unknown): Promise<ApactaErrorResponse> {
  const baseResponse: ApactaErrorResponse = {
    status: 500,
    message: "Unknown error",
    errorType: "unknown",
    cause: error,
    canClientRetry: true,
    fieldErrors: undefined,
    responseData: undefined,
    stack: undefined,
    response: undefined,
    graphqlErrors: [],
  };

  if (error instanceof Error) {
    baseResponse.errorType = "generic-error";
    baseResponse.stack = error.stack;
    baseResponse.message = error.message;
  }

  if (error instanceof ResponseError) {
    baseResponse.errorType = "rest";
    baseResponse.status = error.response.status;
    baseResponse.message = error.response.statusText ?? error.message;
    baseResponse.canClientRetry = error.response.status >= 500 ? true : false;
    try {
      baseResponse.responseData = await error.response.json();
    } catch (e) {
      if (e instanceof Error) {
        baseResponse.responseData = {
          error: "Failed to parse JSON",
          message: e.message,
        };
      }
    }

    if (error.response.status === 422) {
      baseResponse.canClientRetry = false;
      // Handle field errors
      const validationResponse = baseResponse.responseData as BackendValidationError;
      const fieldErrors: ApactaErrorResponse["fieldErrors"] = validationResponse?.data.errors;
      baseResponse.fieldErrors = fieldErrors;
    }
    return {
      ...baseResponse,

      response: error.response,
    };
  }

  if (error instanceof ClientError) {
    baseResponse.errorType = "graphql";
    baseResponse.message = error.message;
    baseResponse.graphqlErrors = error.response.errors ?? [];

    if (!error.response.extensions) {
      // We should've received an extension object, but didn't
      return {
        ...baseResponse,
        status: 500,
        canClientRetry: true,
      };
    }
    // TODO: GraphQL can send many errors
    const fieldErrors: ApactaErrorResponse["fieldErrors"] = error.response.errors
      ? error.response.errors.reduce((acc: FieldErrors, gqlErr) => {
          // If no cause, return empty
          if (gqlErr.cause) {
            return acc;
          }
          const cause = gqlErr.cause as ApactaBackendCause;
          for (const field in cause) {
            for (const rule in cause.errors[field]) {
              acc[field] = { [rule]: cause.errors[field][rule] };
            }
          }

          return acc; // Fallback to empty object
        }, {})
      : undefined;
    return {
      // Validation error in GraphQL
      ...baseResponse,
      status: fieldErrors && Object.keys(fieldErrors).length > 0 ? 422 : 500,
      canClientRetry: false,
      fieldErrors: fieldErrors && Object.keys(fieldErrors).length ? fieldErrors : undefined,
    };
  }

  // We know it's an error object, but we don't know the exact type

  return baseResponse;
}
