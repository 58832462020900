import { useTranslation } from "react-i18next";
import { PageLayout } from "~/lib/ui/page-layout";
import PageTabs from "~/lib/ui/tabs/page-tabs";
import { TabHeaderItem } from "~/lib/ui/tabs/tab-header";
import { Button, Dialog, getIcon } from "~/lib/ui";
import { useNavigate } from "react-router";
import { Suspense } from "react";
import { DateRange } from "~/lib/utils/date/date-utils.types";
import { DateRangePopover } from "~/lib/ui/calendar/date-range-popover";
import { getOffsetDate } from "~/lib/utils/date/date-utils";
import { CACHE_CHECKIN, CheckInDialog } from "~/pages/time-registration/_cmp/check-in-dialog";
import { useLocationIfAvailable } from "~/pages/time-registration/_cmp/use-location-if-available";
import { Tooltip } from "~/lib/ui/tooltips/tooltip";
import { useQuery } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { Spinner } from "~/lib/ui/spinner";
import { SuspenseOutletDataWrapper } from "~/pages/time-registration/_cmp/suspense-outlet-data-wrapper";
import { useDateRangeParams } from "~/lib/utils/use-date-range-params";

export default function RegistrationsPage() {
  const location = useLocationIfAvailable(true);
  const api = useAPI();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const userCheckedInQuery = useQuery({
    queryKey: [CACHE_CHECKIN],
    queryFn: () => api.isCheckedIn({}),
  });

  const [dateRange, setDateParams] = useDateRangeParams({
    from: getOffsetDate(new Date(), -28),
    to: new Date(),
  });

  const isCheckedIn = userCheckedInQuery.data?.data?.checkedIn ?? false;
  const checkedInProjectId = userCheckedInQuery.data?.data?.projectId ?? undefined;

  const pageTabs: Array<TabHeaderItem> = [
    {
      id: "overview",
      label: t("common:weekly_overview"),
      to: "",
      Icon: getIcon("entityOverview"),
    },
    {
      id: "specified",
      label: t("common:specified"),
      to: "specified",
      Icon: getIcon("wall"),
    },
  ];

  const handleDateChange = (newDateRange: DateRange) => {
    setDateParams(newDateRange);
  };

  return (
    <PageLayout
      title={t("common:registration", { count: 2 })}
      renderActions={() => (
        <div className="flex gap-4">
          <Tooltip
            disabled={!!location}
            trigger={
              <div>
                <Dialog
                  trigger={
                    <Button disabled={!location} Icon={getIcon("time")} variant="secondary">
                      {isCheckedIn ? t("common:check_out") : t("common:check_in")}
                    </Button>
                  }
                  render={({ onClose }) => (
                    <CheckInDialog
                      projectId={checkedInProjectId}
                      userCheckedIn={isCheckedIn}
                      onClose={onClose}
                    />
                  )}
                />
              </div>
            }
          >
            <div className="whitespace-pre-line">{t("forms:location_not_supported")}</div>
          </Tooltip>

          <Button Icon={getIcon("add")} variant="tertiary" onClick={() => navigate("new")}>
            {t("common:create", { entity: t("common:registration", { count: 1 }).toLowerCase() })}
          </Button>
        </div>
      )}
      renderDescription={() => (
        <DateRangePopover
          dateFrom={dateRange.from}
          dateTo={dateRange.to}
          onChange={handleDateChange}
        />
      )}
    >
      <PageTabs tabs={pageTabs} preserveSearchParams />
      <Suspense
        fallback={
          <div className="flex items-center justify-center py-12">
            <Spinner />
          </div>
        }
      >
        <SuspenseOutletDataWrapper dateFrom={dateRange.from} dateTo={dateRange.to} />
      </Suspense>
    </PageLayout>
  );
}
