import { ComponentProps, MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "~/lib/ui";
import { ButtonVariant } from "~/lib/ui/buttons/shared/button.shared";
import { useBreakpoints } from "~/lib/utils/tailwind/use-breakpoints";
import { twMerge } from "tailwind-merge";

type Action =
  | {
      // Normal action. Supports an optional type, and onClick
      label: string;
      disabled?: boolean;
      loading?: boolean;
      onClick: MouseEventHandler<HTMLButtonElement>;
      variant?: ButtonVariant;
      type?: Exclude<ComponentProps<typeof Button>["type"], "submit">;
    }
  | {
      // Submit action. Should never have onClick, to prevent double submits
      label: string;
      disabled?: boolean;
      loading?: boolean;
      onClick?: never;
      variant?: ButtonVariant;
      type?: Extract<ComponentProps<typeof Button>["type"], "submit">;
    };

export function DialogFooter({
  primary,
  secondary,
  onClose,
  hideCancel,
}: {
  primary: Action;
  secondary?: Action;
  onClose: () => void;
  hideCancel?: boolean;
}) {
  const { t } = useTranslation();
  const { isBreakpoint } = useBreakpoints();
  const isMobile = isBreakpoint("md"); // Check if we are at or below the md breakpoint

  return (
    <div className={twMerge("pt-8", isMobile && "pb-8")}>
      <hr className="mx-auto w-[95%]"></hr>
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-start pt-6">
          {secondary && (
            <Button
              disabled={secondary.disabled || secondary.loading}
              loading={secondary.loading}
              variant={secondary.variant ?? "secondary"}
              className="justify-center outline-1 outline-green-900"
              onClick={secondary.onClick}
            >
              <span>{secondary.label}</span>
            </Button>
          )}
        </div>

        <div className="flex items-center justify-end gap-6 pt-6">
          {!hideCancel && (
            <Button
              className="cursor-pointer justify-center underline hover:text-hover"
              onClick={() => onClose()}
            >
              {t("common:cancel")}
            </Button>
          )}
          <Button
            type={primary.type ?? "submit"}
            variant={primary.variant ?? "tertiary"}
            className="justify-center outline-1 outline-green-900"
            disabled={primary.disabled || primary.loading}
            loading={primary.loading}
            onClick={primary.onClick}
          >
            <span>{primary.label}</span>
          </Button>
        </div>
      </div>
    </div>
  );
}
