import { ComponentProps, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { useMe } from "~/lib/auth/use-me";
import { useFormState } from "~/lib/form-state";
import { Button, getIcon, LabelInput } from "~/lib/ui";
import { TextArea } from "~/lib/ui/form-elements";
import { CheckboxLine } from "~/lib/ui/form-elements/checkbox-line";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { blobToBase64 } from "~/lib/utils/utils";
import { FullScreenFilePreview } from "~/lib/ui/media/full-screen-file-preview";
import { emailRequired } from "~/lib/form-state/zod";

export function InvoiceSendFragment({
  onChange,
  projectId,
  customerId,
  contactPersonId,
}: {
  projectId: string;
  customerId?: string;
  contactPersonId?: string;
  onChange: (change: {
    recipientEmail: string;
    subject: string;
    body: string;
    attachProjectOverviewPDF: boolean;
    isValid: boolean;
  }) => void;
}) {
  const { t } = useTranslation();
  const me = useMe();
  const api = useAPI();

  const [projectPreviewBlob, setProjectPreviewBlog] = useState<string>();
  const projectPreview = useMutation({
    mutationKey: ["projectPreview", projectId],
    mutationFn: async () => {
      const blob = await api.viewAsPDF({ projectId });
      return blobToBase64(blob);
    },
    onSuccess: () => {
      // disable default toast
    },
  });

  const formstate = useFormState({
    schema: {
      recipientEmail: emailRequired(),
      subject: z.string().min(1),
      body: z.string().min(1),
      attachProjectOverviewPDF: z.boolean(),
    },
    initialValues: {
      recipientEmail: "",
      subject: t("invoices:invoice_email_subject", {
        defaultValue: "Invoice from {{companyName}}",
        replace: { companyName: me.company.name },
      }),
      body: t("invoices:invoice_email_body", {
        defaultValue:
          "Thanks for using {{companyName}}.\n\nEnclosed is invoice {invoice_number} of {total_amount} DKK with VAT.\n\nIf you have any questions, you're always welcome to contact us.\n\nBest regards,\n{{companyName}}",
        replace: {
          companyName: me.company.name,
          // Note: the `invoice_number` and `total_amount` are placeholders that are replaced in the backend
          // - Don't replace them here
        },
      }),
      attachProjectOverviewPDF: false,
    },
    mutationError: null, // DEBT: mutation isn't available here
  });
  const values = formstate.getValues();

  // Looks up emails for the default email field
  const emailQuery = useQuery({
    refetchOnWindowFocus: true,
    queryKey: ["emailForInvoice", customerId, contactPersonId],
    queryFn: async () => {
      // Use the customer email if available
      if (customerId) {
        const resC = await api.getContact({ contactId: customerId });
        if (resC.data.email) {
          handleFieldChange("recipientEmail", resC.data.email);
        }
      }
    },
  });

  function handleFieldChange<F extends keyof typeof values>(field: F, value: (typeof values)[F]) {
    formstate.onChange(field, value);
    // Inform parent
    onChange({
      ...values,
      [field]: value,
      isValid: formstate.isValid,
    });
  }

  async function handleProjectPreview() {
    const res = await projectPreview.mutateAsync();
    setProjectPreviewBlog(res);
  }

  // This is not ideal. I'm considering adding event listeners to formstate.
  // So, that it's possible for the parent to set a callback that triggers on change.
  // TODO: Revisit this later. Works well enough for now.
  useEffect(() => {
    onChange({ ...values, isValid: formstate.isValid });
  }, [formstate.isValid]);

  const disableEmailField = emailQuery.isPending && !formstate.isModified;

  return (
    <>
      <LabelInput
        required
        disabled={disableEmailField}
        placeholder={disableEmailField ? t("common:loading") : undefined}
        defaultValue={formstate.getValue("recipientEmail")}
        label={t("common:email")}
        onChange={(e) => handleFieldChange("recipientEmail", e.currentTarget.value)}
        type="email"
        error={formstate.getField("recipientEmail").errors[0]}
      />
      <LabelInput
        defaultValue={formstate.getValue("subject")}
        label={t("common:subject")}
        required
        onChange={(e) => handleFieldChange("subject", e.currentTarget.value)}
        error={formstate.getField("subject").errors[0]}
      />
      <TextArea
        initialValue={formstate.getValue("body")}
        errorMessage={formstate.getField("body").errors[0]}
        onChange={(s) => handleFieldChange("body", s)}
        label={t("common:message")}
        fullHeight={true}
        className="h-36"
        required={true}
      />
      <div className="mb-4 flex flex-row items-center justify-between">
        <CheckboxLine
          label={t("projects:attach_project_overview_pdf")}
          checked={values.attachProjectOverviewPDF}
          onChange={(e) => handleFieldChange("attachProjectOverviewPDF", e.currentTarget.checked)}
        />
        <Button
          loading={projectPreview.isPending}
          disabled={projectPreview.isPending}
          size="small"
          variant="primary"
          onClick={handleProjectPreview}
          Icon={getIcon("preview")}
        >
          {t("common:see")} {t("projects:overview").toLocaleLowerCase()}
        </Button>
      </div>
      <FullScreenFilePreview
        open={!!projectPreviewBlob}
        fileUrl={projectPreviewBlob}
        onClose={() => setProjectPreviewBlog(undefined)}
      />
    </>
  );
}

export type SendData = Parameters<ComponentProps<typeof InvoiceSendFragment>["onChange"]>[0];
