import { useNavigate, useOutletContext } from "react-router";
import { CreateProductRequest, CreateProductRequestProductRuleEnum } from "@apacta/sdk";
import { useFormState } from "~/lib/form-state";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { useMutation } from "@tanstack/react-query";
import { useToasts } from "~/lib/toast/use-toasts";
import { ProductRulesSelect } from "~/pages/products/_cmp/product-rules-select";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import { OutletContext } from "../[id]";
import { Button, Label, LabelInput } from "~/lib/ui";
import { Input } from "~/lib/ui/form-elements";
import { Tooltip } from "~/lib/ui/tooltips/tooltip";

export default function OverviewProductPage() {
  const api = useAPI();
  const { product, refetch } = useOutletContext<OutletContext>();
  const { t } = useTranslation();
  const toast = useToasts();
  const navigate = useNavigate();
  const [{ referrer }] = useTypedSearchParams<{ referrer: string }>();

  const editProductMutation = useMutation({
    mutationFn: (args: CreateProductRequest) =>
      api.editProduct({ productId: product.id, createProductRequest: args }),
    onSuccess: async () => {
      toast.showTemplate("CHANGES_SAVED", { timeout: 10000 });
      resetInitialValues(getValues());
      await refetch();
      if (referrer) {
        navigate(referrer);
      }
    },
  });

  const { isModified, isValid, onChange, getValue, getValues, resetInitialValues } = useFormState({
    schema: {
      name: z.string().max(255),
      number: z.string().max(255).optional(),
      barcode: z.string().max(255).optional(),
      unit: z.string().max(255).optional(),
      product_rule: z.string().nullable(),
    },
    initialValues: {
      name: product.name,
      number: product.productNumber ?? undefined,
      barcode: product.barcode ?? undefined,
      unit: product.unit ?? undefined,
      product_rule: product.productRule ?? null,
    },
    mutationError: editProductMutation.error,
  });

  function handleSave() {
    editProductMutation.mutate({
      name: getValue("name"),
      productNumber: getValue("number"),
      barcode: getValue("barcode"),
      productRule: getValue("product_rule") as CreateProductRequestProductRuleEnum,
      unit: getValue("unit") ?? null,
    });
  }

  return (
    <div className="flex w-full flex-col gap-12">
      <div className="flex flex-col gap-2 sm:flex-row sm:justify-between">
        <h2 className="m-0">{t("products:product_overview")}</h2>
        <div>
          <Button
            onClick={handleSave}
            variant="tertiary"
            disabled={!isValid || editProductMutation.isPending || !isModified}
            loading={editProductMutation.isPending}
          >
            {t("common:save_changes")}
          </Button>
        </div>
      </div>
      <div className="flex-1">
        <div className="flex flex-col gap-6 bg-white p-4 shadow sm:gap-8 sm:rounded-lg md:flex-row">
          <div className="flex flex-1 flex-col gap-6">
            <LabelInput
              defaultValue={getValue("name")}
              onChange={(e) => onChange("name", e.currentTarget.value)}
              label={t("common:name")}
            />
            <ProductRulesSelect
              initialProductRule={getValue("product_rule") ?? "NONE"}
              onChange={(productRuleValue) => {
                if (productRuleValue === undefined) {
                  onChange("product_rule", null);
                } else {
                  onChange("product_rule", productRuleValue);
                }
              }}
            />
            <div className="flex flex-col gap-6 md:flex-row">
              <div className="flex flex-col justify-start md:basis-1/4">
                <div className="flex gap-1">
                  <Label>{t("products:product_unit")}</Label>
                  <Tooltip>{t("products:product_unit_tooltip")}</Tooltip>
                </div>
                <Input
                  defaultValue={getValue("unit")}
                  onChange={(e) => onChange("unit", e.currentTarget.value)}
                />
              </div>
              <div className="flex flex-1 flex-col gap-6 md:basis-1/4">
                <LabelInput
                  defaultValue={getValue("number")}
                  onChange={(e) => onChange("number", e.currentTarget.value)}
                  label={t("products:number")}
                />
              </div>
              <div className="flex flex-1 flex-col gap-6 md:basis-2/4">
                <LabelInput
                  defaultValue={getValue("barcode")}
                  onChange={(e) => onChange("barcode", e.currentTarget.value)}
                  label={t("common:barcode")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
