import { Action, FormReducerState, FormReducerStateMeta, MapFromZod, SchemaMap } from "../types";
import { setRootValues, setSingleField, setValuesReducer } from "./helpers";

export function formStateReducer<Schema extends SchemaMap>(
  state: FormReducerState<Schema>,
  action: Action<Schema>
): FormReducerState<Schema> {
  switch (action.type) {
    case "setValues":
      // Set and validate multiple values
      return setRootValues(setValuesReducer<Schema>(state, action));
    case "onChange":
      return setRootValues(
        setSingleField(state, action.field, {
          ...state.meta[action.field],
          touched: true,
          value: action.value,
          errors: [],
          status: "pending",
          required: !state.schema[action.field].isOptional(),
        })
      );
    case "validate":
      return setRootValues(setSingleField(state, action.field, state.meta[action.field], true));
    case "parseBackendErrors":
      const newState = { ...state };
      for (const field in action.errors) {
        if (newState.meta[field as string]) {
          const fieldErrors = action.errors[field];
          // Push the errors to the field
          for (const rule in action.errors[field]) {
            const errVal = fieldErrors[rule];
            if (!errVal) continue;
            if (newState.meta[field].errors.includes(errVal)) continue;

            newState.meta[field].errors.push(errVal);
          }

          newState.meta[field].status = "invalid";
        } else {
          console.warn(`Received backend error for field that I don't recognize: ${field}`);
        }
      }
      return newState;
  }
  throw new Error("Unhandled action type: " + action.type);
}

export function initialFormState<Schema extends SchemaMap>({
  schema,
  initialValues,
}: {
  schema: Schema;
  initialValues: MapFromZod<Schema>;
}): FormReducerState<Schema> {
  let newState = {
    schema,
    meta: {} as FormReducerStateMeta<Schema>,
    isValid: false,
    isPending: false,
    isModified: false,
    modifiedFields: new Array<string>(),
    invalidFields: new Array<string>(),
  };
  Object.keys(schema).forEach((field) => {
    newState = setSingleField(
      newState,
      field,
      {
        value: initialValues[field],
        initialValue: initialValues[field],
        errors: [],
        status: "pending",
        touched: false,
        modified: false,
        required: !schema[field].isOptional(),
      },
      true
    );
  });
  return setRootValues(newState);
}
