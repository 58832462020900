import { Invoice } from "@apacta/sdk";
import { useDataColumns } from "~/lib/ui/data-table";
import { InvoiceImage } from "./invoice-image";
import { getInvoiceEntity, getInvoiceType } from "./get-invoice-type";
import { useTranslation } from "react-i18next";
import { getIcon } from "~/lib/ui";
import * as Popover from "@radix-ui/react-popover";
import { ActionButtons } from "~/lib/ui/action-buttons";
import { InvoiceActivityLogContent } from "./activity-log-content";
import { useState } from "react";
import { useAPI } from "~/lib/api";
import { useQueryClient } from "@tanstack/react-query";
import { CACHE_INVOICES } from "..";
import { useNavigate } from "react-router";
import { ProjectCell } from "~/lib/ui/table/cells/project-cell";
import { CustomerCell } from "~/lib/ui/table/cells/customer-cell";
import { DateCell } from "~/lib/ui/table/cells/date-cell";
import { CurrencyCell } from "~/lib/ui/table/cells/currency-cell";
import { useLinking } from "~/lib/utils/use-linking";
import { isVisible } from "./invoice-logic";

export function useInvoiceTableColumns(opts: { onSendInvoice: (invoice: Invoice) => void }) {
  const { t } = useTranslation();
  const api = useAPI();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const link = useLinking();

  const [selectedInvoiceForActivityLog, setSelectedInvoiceForActivityLog] = useState<string>();

  async function handleDelete(id: string) {
    await api.deleteInvoice({ invoiceId: id });
    await queryClient.invalidateQueries({ queryKey: [CACHE_INVOICES, "index"] });
  }

  async function handleCreditNote(id: string) {
    const res = await api.duplicateInvoice({ invoiceId: id, asCreditNote: true });
    if (!res.data?.id) {
      console.error("Didn't receive an ID for the duplicated invoice. Pending API changes?");
      return;
    }
    await queryClient.invalidateQueries({ queryKey: [CACHE_INVOICES, "index"] });
    navigate(link.invoice(res.data.id)!); // always true
  }

  const columns = useDataColumns<Invoice>(
    (columnHelper) => [
      columnHelper.accessor("invoiceNumber", {
        id: "invoice_number",
        header: t("invoices:invoice_number", "Invoice number"),
        cell: ({ row }) => (
          <div className="flex min-w-3 items-center justify-between gap-2">
            <div className="text-right">{row.original.invoiceNumber ?? "-"}</div>
            <InvoiceImage invoice={row.original} />
          </div>
        ),
        meta: {
          className: "w-1/24 group",
          href: (invoice) => link.invoice(invoice.id),
        },
        enableSorting: true,
      }),
      columnHelper.accessor("created", {
        id: "issued_date",
        header: t("invoices:issued_date", "Issued date"),
        cell: ({ row }) => <DateCell date={row.original.issuedDate} />,
        enableSorting: true,
        meta: {
          href: (invoice) => link.invoice(invoice.id),
        },
      }),
      columnHelper.display({
        id: "status",
        cell: ({ row }) => getInvoiceType({ invoice: row.original, t }),
        meta: {
          href: (invoice) => link.invoice(invoice.id),
        },
      }),
      columnHelper.accessor("project.name", {
        id: "project",
        header: t("common:project", { count: 1 }),
        cell: ({ row }) => <ProjectCell project={row.original.project} />,
      }),
      columnHelper.accessor("netPayment", {
        header: t("finance:total_with_vat", "Total w. VAT"),
        cell: ({ row }) => <CurrencyCell value={row.original.netPayment} />,
        meta: {
          className: "text-right",
        },
      }),
      columnHelper.accessor("grossPayment", {
        header: t("finance:total_wo_vat"),
        cell: ({ row }) => <CurrencyCell value={row.original.grossPayment} />,
        meta: {
          className: "text-right",
        },
      }),
      columnHelper.accessor("contact.name", {
        id: "customer",
        header: t("common:customer", { count: 1 }),
        cell: ({ row }) => <CustomerCell customer={row.original.contact} />,
        meta: {
          skeletonType: "avatar",
        },
      }),
      columnHelper.display({
        id: "actions",
        meta: {
          className: "text-right",
        },
        cell: ({ row }) => {
          return (
            <>
              <Popover.Root
                open={selectedInvoiceForActivityLog === row.original.id}
                onOpenChange={() => {
                  setSelectedInvoiceForActivityLog(undefined);
                }}
              >
                <Popover.Anchor></Popover.Anchor>
                <Popover.Portal>
                  <Popover.Content>
                    <InvoiceActivityLogContent invoiceId={row.original.id} />
                  </Popover.Content>
                </Popover.Portal>
              </Popover.Root>
              <ActionButtons
                size="small"
                actions={[
                  {
                    Icon: getIcon("edit"),
                    label: t("common:edit"),
                    href: link.invoice(row.original.id),
                    isHidden: !isVisible("edit-action-button", row.original),
                  },
                  {
                    Icon: getIcon("view"),
                    label: t("common:view"),
                    href: link.invoice(row.original.id),
                    isHidden: !isVisible("view-action-button", row.original),
                  },
                  {
                    Icon: getIcon("send"),
                    label: t("common:send"),
                    onClick: () => {
                      opts.onSendInvoice(row.original);
                    },
                    isHidden: !isVisible("send-action-button", row.original),
                  },
                  {
                    Icon: getIcon("log"),
                    label: t("common:activity_log", "Activity log"),
                    onClick: () => {
                      setSelectedInvoiceForActivityLog(row.original.id);
                    },
                    isHidden: !isVisible("activity-log-action-button", row.original),
                  },
                  {
                    Icon: getIcon("delete"),
                    label: t("common:delete"),
                    confirm: {
                      entity: getInvoiceEntity(row.original),
                      action: "delete",
                    },
                    isHidden: !isVisible("delete-action-button", row.original),
                    onClick: () => handleDelete(row.original.id),
                  },
                  {
                    Icon: getIcon("credit"),
                    label: t("invoices:create_credit_note"),
                    isHidden: !isVisible("credit-action-button", row.original),
                    onClick: () => handleCreditNote(row.original.id),
                  },
                ]}
              />
            </>
          );
        },
      }),
    ],
    [selectedInvoiceForActivityLog]
  );
  return columns;
}
